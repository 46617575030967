
function SectionContactForm() {
    const addfontsz={
        fontSize: '12pt'
    };
    const ptxtsize={
        fontSize: '12pt'
    };
    const pdtop={
        paddingTop: '20px'
    };
    const displaysts={
        display: 'none'
    };
    return(
        <>
        
        <section id="contact" className="contact">
        <div className="container">

            <div className="row justify-content-center" data-aos="fade-up">

            <div className="col-lg-10">

                <div className="info-wrap">
                <div className="row">
                    <div className="col-lg-6 info">
                    
                    <p><img src="../assets/img/logo.png" alt="" className="img-fluid"/></p>
                    <h4 style={addfontsz}>(An institute under M/S Shraddha Group of Education)</h4>
                    <p style={ptxtsize}>195, Kailash Das Road, Gauripur,<br/>Nearest Railway Station-Naihati
                    <br/>Dist - North 24 Parganas<br/>West Bengal - 743166</p>
                    </div>

                    <div className="col-lg-6 info mt-4 mt-lg-0" style={pdtop}>
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>dshaw0112@gmail.com</p>
                    <hr/>
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>9339764227</p>
                    <hr/>
                    <i className="bi bi-whatsapp"></i>
                    <h4>Whatsapp:</h4>
                    <p>8013181226</p>
                    </div>


                </div>
                </div>

            </div>

            </div>

            <div className="row mt-5 justify-content-center" data-aos="fade-up">
            <div className="col-lg-10">
                <div className="row">
                    <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                    </div>
                </div>
                <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                </div>
                <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div className="my-3" style={displaysts}>
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="form-group mt-3">
                <div className="text-center"><button type="button">Send Message</button></div>
                </div>
                

            </div>

            </div>

        </div>
        </section>

        </>
    );
}
export default SectionContactForm;